import React from 'react';

import './footer.css';

export default function Footer() {
  return (
    <footer>
      <div>
        Website made by Sam Ebersole.
      </div>
    </footer>
  );
}
