import React from 'react';

import HEADSHOT from './headshot.JPG';
import './about.css';

export default function About() {
  return (
    <div className="about">
      <h1>About</h1>
      <div>
        <img src={HEADSHOT} alt="headshot" />
        <p>
          A Product Designer with a strong mechanical engineering background, I’m committed to
          Human Centered Design as a method for improving people’s lives. I have gained versatile
          problem solving skills in completing my degree and vital communication skills in my
          projects, both of which you will see demonstrated on this website. These experiences have
          made me a curious, compassionate, and thorough designer, which allows me to spotlight the
          fundamental problems users experience and develop a solution within the scope of the
          project. I aspire to make a positive impact with my work and grow as a designer with a
          passionate and dynamic team.
        </p>
      </div>
      <section>
        <h2>Contact</h2>
        <a href="mailto:AchillesB.Design@Gmail.com">AchillesB.Design@Gmail.com</a>
        <br />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/achilles-batista"
        >
          LinkedIn
        </a>
      </section>
    </div>
  );
}
